import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as clipboard from 'clipboard-polyfill';

import { TOAST_OPTIONS } from '../../config';
import {
  useDeleteCategoryMutation,
  useDeleteMenuImageMutation,
  useUpdateCategoryMutation,
  useUpdateMenuImageMutation,
} from '../../redux/api/menu';
import { ReactComponent as PlusIcon } from '../../static/plus.svg';
import { Button } from '../../view/Button';
import { MenuLanguageSelect } from '../../view/MenuLanguageSelect';
import Pagination from '../../view/Pagination';
import SearchBar from '../../view/SearchBar';
import { Loader } from '../Loader';
import { Table } from '../MenuTable';
import FileBlock from '../QRBody/components/Promo/components/FileBlock';

import CreateCategoryModal from './components/CreateCategoryModal';
import VisibleLanguageSelect from './components/VisibleLanguageSelect';
import { useMenuBody } from './hooks/useMenuBody';

const MenuBody = () => {
  const {
    data,
    isLoader,
    refetch,
    open,
    isOpen,
    createMenuHandler,
    close,
    t,
    categories,
    searchValue,
    handleSearch,
    setCategories,
    setActiveLanguages,
    activeLanguages,
    adminId,
    id: userId,
  } = useMenuBody();

  const [update] = useUpdateCategoryMutation();
  const [deleteCategory] = useDeleteCategoryMutation();
  const [updateMenuImage, { isLoading }] = useUpdateMenuImageMutation();
  const [deleteImage, { isLoading: isDeleteLoading }] = useDeleteMenuImageMutation();
  const navigate = useNavigate();

  const [file, setFile] = useState<File>();
  const fileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0] as File);
    const formData = new FormData();
    formData.append('file', e.target.files?.[0] as File);
    updateMenuImage({ id: userId, body: formData });
  };

  const editHandler = (id: number) => () => {
    navigate(`${id}`);
  };

  const deleteHandler = (id: number) => () => {
    deleteCategory(id).then(refetch);
  };

  const onPageChange = (page: number) => {
    if (data) {
      setCategories(data.menu_categories.slice((page - 1) * 8 + 1, page * 8 + 1));
    }
  };

  const copyHandler = () => {
    clipboard.writeText(data?.link || '').then(() => {
      toast.info('Copied to clipboard', { ...TOAST_OPTIONS, position: 'top-center' });
    });
  };

  const handleDelete = () => {
    deleteImage({ id: userId }).then(() => setFile(undefined));
  };

  return (
    <div className="bg-white p-[2.604vw] md:px-[3.255vw] md:py-[5.208vw] xs:py-[8vw] xs:px-[5.333vw]">
      {isLoader || ((isLoading || isDeleteLoading) && <Loader />)}
      {data ? (
        <>
          <div className="flex flex-col w-[20vw] md:w-[40vw] xs:w-full mb-[10px]">
            <FileBlock
              onChange={fileChange}
              file={file}
              imageFromDB={data.menuPromoImage ? data.menuPromoImage : ''}
              className="mb-[24px]"
              description={t('foodMenu.category.image') as string}
            />
            <Button onClick={handleDelete}>{t('foodMenu.deleteBtn')}</Button>
          </div>
          <div className="flex justify-between items-center mb-[16px] md:flex-col md:items-start md:gap-[10px]">
            <Button
              onClick={open}
              ghost={true}
              className="px-[1.354vw] py-[0.625vw] flex gap-[0.521vw] xs:w-full xs:order-2"
            >
              <PlusIcon />
              <p>{t('foodMenu.createCategory')}</p>
            </Button>
            <VisibleLanguageSelect
              refetch={refetch}
              languages={data.languages}
              setActiveLanguages={setActiveLanguages}
              activeLanguages={activeLanguages}
            />
            <MenuLanguageSelect items={data?.languages} refetch={refetch} />
          </div>
          {!!adminId && (
            <button
              className="mb-[16px] text-mainBlack xs:text-ellipsis xs:overflow-hidden xs:w-full xs:text-center"
              onClick={copyHandler}
            >
              {t('foodMenu.link')}: {data.link}
            </button>
          )}
        </>
      ) : (
        <Button ghost={true} onClick={createMenuHandler} className="mb-[16px]">
          {t('foodMenu.createBtn')}
        </Button>
      )}
      <div
        className={`p-[1.042vw] shadow-org rounded-[0.625vw] h-[44.479vw] flex flex-col gap-[1.042vw]
           md:p-[2.604vw] md:rounded-[1.563vw] md:h-[113.542vw] md:gap-[2.083vw]
           xs:p-[5.333vw] xs:rounded-[3.2vw] xs:h-[165.333vw] xs:gap-[3.2vw] md:overflow-hidden`}
      >
        <p className="text-mainBlack text-[1.25vw] leading-[1.771vw] md:text-[2.604vw] md:leading-[3.906vw] xs:text-[4.533vw] xs:leading-[6.4vw]">
          {t('foodMenu.table.categoryTitle')}
        </p>
        <SearchBar searchValue={searchValue} handleSearch={handleSearch} />
        {data && !!categories.length ? (
          <>
            <Table
              data={categories}
              maxOrder={data.maxOrder}
              refetch={refetch}
              updateFn={update}
              acceptHandler={editHandler}
              deleteHandler={deleteHandler}
              link={true}
            />
            <Pagination pages={data.menu_categories.length} onPageChange={onPageChange} />
          </>
        ) : (
          <p
            className={`text-[1.25vw] leading-[1.771vw] text-lowGray mt-[2.083vw] text-center
            md:mt-[5.208vw] xs:mt-[10.667vw] md:text-[2.083vw] md:leading-[3.125vw] xs:text-[3.733vw] xs:leading-[5.333vw]`}
          >
            {t('organizations.noData')}
          </p>
        )}
        {isOpen && (
          <CreateCategoryModal onClose={close} refetch={refetch} maxOrder={data?.maxOrder} />
        )}
      </div>
    </div>
  );
};

export default MenuBody;
