import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClickOutside } from '../../../../hooks/useClickOutside';
import { useDisclosure } from '../../../../hooks/useDisclosure';
import { useAppDispatch } from '../../../../redux/hooks';
import { updateMenuLang } from '../../../../redux/slices/userSlice';
import { ReactComponent as ArrowDown } from '../../../../static/arrow_down.svg';

interface IProps {
  className?: string;
  items: string[];
  refetch?: () => void;
}

export const LanguageSelect: FC<IProps> = ({ className, items, refetch }) => {
  const { i18n } = useTranslation();
  const [visibleLang, setVisibleLang] = useState(items[0]);
  const dispatch = useAppDispatch();
  const { isOpen, open, close } = useDisclosure();
  const listRef = useRef<HTMLUListElement>(null);
  useClickOutside(listRef, close);

  const handleLangChange = (lng: string) => () => {
    close();
    setVisibleLang(lng);
    i18n.changeLanguage(lng);
    dispatch(updateMenuLang(lng));
    refetch?.();
  };

  useEffect(() => {
    const correctLang =
      i18n.language.split('-').length > 1 ? i18n.language.split('-')[0] : i18n.language;
    const lang = items.includes(correctLang) ? correctLang : items[0];
    dispatch(updateMenuLang(lang));
    setVisibleLang(lang);
    i18n.changeLanguage(lang);
  }, [items]);

  return (
    <div
      className={`h-[40px] py-[2.113vw] pl-[19px] pr-[16px] relative rounded-[26.667vw] dark:bg-none dark:bg-darkBg bg-white max-w-[93px] min-w-[93px] font-sourceSans ${className}`}
    >
      <button className="flex items-center gap-[2.113vw]" onClick={open}>
        <p className="font-bold dark:text-white text-black xs:text-[4.267vw] xs:leading-[6.4vw] uppercase">
          {visibleLang}
        </p>
        <ArrowDown
          className={`transition-transform xs:w-[6.4vw] xs:h-[6.4vw] ${
            isOpen ? 'rotate-[180deg]' : 'rotate-0'
          } [&>path]:fill-black [&>path]:dark:fill-white`}
        />
      </button>
      {isOpen && (
        <ul
          ref={listRef}
          className={`absolute -bottom-[0.208vw] right-0 translate-y-full flex flex-col cursor-pointer
             dark:bg-languageBg bg-[#F7F7F7] w-[23.467vw] px-[5.333vw] py-[2.667vw] rounded-[5.333vw] gap-[2.133vw]
            `}
        >
          {items.map(lng => (
            <li
              onClick={handleLangChange(lng)}
              className="font-bold dark:text-white text-black xs:text-[4.267vw] xs:leading-[6.4vw] xs:rounded-[5.333vw]"
              key={lng}
            >
              {lng.toUpperCase()}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
